import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import OpenPortalButton from './OpenPortalButton';

const Profile = () => {
  const { user } = useAuth0();

  return (
    <div style={{padding: "1vh"}}>
      <u>Information</u><br/>
      {user.name}

      <br/><br/><br/>
      <OpenPortalButton />
    </div>
  );
}

export default withAuthenticationRequired(Profile, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (<div>Logging in...</div>)
});
