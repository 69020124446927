import { Col, Container, Row } from "react-bootstrap";

import LoginButton from './LoginButton';
import Logo from "./sticky-logo.png";
import LogoutButton from './LogoutButton';

function Header() {
    return (
        <header className="header">
            <Container>
                <Row>
                    <Col md={2}><img style={{color: "white", width: "15vw"}} src={Logo} /></Col>
                    <Col md={8}></Col>
                    <Col md={2}><LoginButton /><LogoutButton /></Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;