import 'bootstrap/dist/css/bootstrap.min.css';

import App from './app/App';
import { Auth0Provider } from "@auth0/auth0-react"
import { BrowserRouter } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-9srqsm6e.us.auth0.com"
      clientId="ZXmOpjBUMgPtvqerApDKRn4U4xFs6Hwe"
      redirectUri={process.env.NODE_ENV == "development" ? "http://localhost:3000" :"https://my.aq.technology"}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
