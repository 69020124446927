import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { BsArrowRightSquare } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { React } from 'react';

const OpenPortalButton = () => {
    const { user } = useAuth0();

    return (
        <Button>
            <a 
                style={{textDecoration: "none", color:"white"}} 
                href={user['https://my.aq.technology/portal']} 
                rel="noreferrer"
                target="_blank">Statements &amp; Invoices </a>
                
            <BsArrowRightSquare/>
        </Button>
    );

};

export default withAuthenticationRequired(OpenPortalButton);