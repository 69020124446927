import { Button } from "react-bootstrap";
import { HiOutlineLogout } from "react-icons/hi";
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LogoutButton() {
  const {
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0();

  return isAuthenticated && !isLoading && (
    <Button variant="outline-light" onClick={() => {
      logout({ returnTo: "https://aq.technology" });
    }}>Log out <HiOutlineLogout/> </Button>
  );
}

export default LogoutButton;