import { Col, Container, Row } from "react-bootstrap";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

import React from 'react';

function Footer() {
    return (
        <Container className="footer">
            <Row>
                <Col>
                    <div className="media media-none--md">
                        
                        <div className="media-body space-md">
                        <a style={{ textDecoration: "none", color: "#ffffff" }} href="tel:12152584388">
                                <FaPhoneAlt />
                        </a>
                            <h3 className="item-title">(267) 578-0002</h3>
                            <div className="item-subtitle">Mon-Fri : 9.00 am - 6.00 pm</div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="media media-none--md">
                        <a style={{ textDecoration: "none", color: "#ffffff" }} href="mailto:contact@aq.technology">
                                <FaEnvelope />
                        </a>
                        <div className="media-body space-md">
                            <h3 className="item-title">contact@aq.technology</h3>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;