import "./app.css";

import { Route, Routes } from "react-router-dom";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import Profile from '../profile/Profile';
import React from "react";
import ReactGA from "react-ga4";

function App() {

  ReactGA.initialize("G-B8JFG9KEQ6");
  ReactGA.send("pageview");
  return (
    <div className="app">
      <Header />
      <div className="page">
        <Routes>
          <Route path="/" exact element={<Profile />}></Route>
        </Routes>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
