import { Button } from "react-bootstrap";
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LoginButton() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  return !isAuthenticated && !isLoading && (
    <Button variant="outline-light" onClick={loginWithRedirect}>Log in</Button>
  );
}

export default LoginButton;